html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --dark-accent: #002949;
  --mid-accent: #003659;
  --light-accent: #00b1a1;
  --action-positive: #2b9428;
  --action-negative: #c62d2d;
  --action-warning: #918922;
  --background-negative: #e68d8d;
  --modal-background: #f5f5f5;
  --content-background: #ffffff;
  --page-background: #e3e3e3;
  --border-colour: #d9d9d9;
  --light-text-colour: #fbfbfb;
  --dark-text-colour: #707070; /* R8ER colour scheme */
  --primary-text-colour: var(--dark-text-colour);
  --secondary-text-colour: var(--light-text-colour);
  --input-background: #e8e8e8; /* R8ER colour scheme */
  --separator-colour: #c4c4c4;
  line-height: 1.5;
  --height-increment: 1;
  --width-increment: 1;
}

.darkMode {
  --input-background: #172b40; /* R8ER colour scheme */
  --dark-accent: #002949;
  --mid-accent: #003659;
  --light-accent: #00b1a1;
  --modal-background: #0a1c2f;
  --content-background: #0a1c2f;
  --page-background: #06182b;
  --border-colour: #172b40;
  --separator-colour: #172b40;
  --primary-text-colour: var(--light-text-colour);
  --secondary-text-colour: var(--dark-text-colour);
}

:root {
  --brand-primary: #00b1a1;
  --brand-primary-dark: #00ab9d;
  --brand-secondary: #00325a;
  --section-padding-small: 50px 0;
  --section-padding-normal: 100px 0;
  --section-padding-large: 190px 0;
  --heading-1-size: 4rem;
  --heading-2-size: 2.625rem;
  --brand-blue-highlight: #08345c;
}

@media screen and (max-width: 980px) {
  :root {
    --section-padding-normal: 50px 0;
    --heading-1-size: 1.75rem;
    --heading-2-size: 1.375rem;
  }
}

body.scroll-locked {
  overflow: hidden;
}

/**
 * Consistent typography & sizings
 */
:root {
  --font-size-base: 16px;
}

.h2--standard {
  font-size: calc(var(--font-size-base) * 2.625);
  font-weight: 500;
  line-height: 1.2em;
}

.h2--underlined {
  margin-bottom: 8rem !important;
  font-size: calc(var(--font-size-base) * 2.625);
  font-weight: 400;
  line-height: 1.2em;
  color: var(--brand-secondary) !important;
}

.h2--underlined::after {
  content: "";
  display: block;
  width: 152px;
  height: 3px;
  margin: 1.5rem auto 0;
  background-color: #1aaa9d;
}

.ul--standard {
  font-size: calc(var(--font-size-base) * 1.375);
  font-weight: normal;
  line-height: 1.25em;
  color: var(--brand-secondary);
}

.ul--standard li {
  color: inherit;
  font-size: inherit;
}

.p--standard,
.ghost-container p {
  font-size: calc(var(--font-size-base) * 1.375);
  font-weight: normal;
  line-height: 1.25em;
  margin-top: 0;
  margin-bottom: 0.5em !important;
}

.ghost-container p a {
  text-decoration: underline;
}

pre {
  font-family: inherit;
}

@media screen and (max-width: 980px) {
  :root {
    --font-size-base: 12px;
  }

  .h2--standard {
    width: auto !important;
    font-size: calc(var(--font-size-base) * 2.25);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.a {
  color: var(--brand-primary);
  font-weight: 500;
}

* {
  box-sizing: border-box;
  color: inherit;
}

hr {
  border-color: var(--separator-colour);
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  min-height: 100vh;
}

#nprogress {
  z-index: 100000;
  position: relative;
}

@font-face {
  font-family: "DINRoundPro";
  src: url("/fonts/DINRoundPro-Black.eot");
  src: local("/fonts/DINRoundPro-Black"),
    url("/fonts/DINRoundPro-Black.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DINRoundPro-Black.woff2") format("woff2"),
    url("/fonts/DINRoundPro-Black.woff") format("woff"),
    url("/fonts/DINRoundPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "DINRoundPro";
  src: url("/fonts/DINRoundPro-Medi.eot");
  src: local("/fonts/DINRoundPro-Medi"),
    url("/fonts/DINRoundPro-Medi.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DINRoundPro-Medi.woff2") format("woff2"),
    url("/fonts/DINRoundPro-Medi.woff") format("woff"),
    url("/fonts/DINRoundPro-Medi.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DINRoundPro";
  src: url("/fonts/DINRoundPro-Light.eot");
  src: local("/fonts/DINRoundPro-Light"),
    url("/fonts/DINRoundPro-Light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DINRoundPro-Light.woff2") format("woff2"),
    url("/fonts/DINRoundPro-Light.woff") format("woff"),
    url("/fonts/DINRoundPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "DINRoundPro";
  src: url("/fonts/DINRoundPro.eot");
  src: local("/fonts/DINRoundPro"),
    url("/fonts/DINRoundPro.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DINRoundPro.woff2") format("woff2"),
    url("/fonts/DINRoundPro.woff") format("woff"),
    url("/fonts/DINRoundPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINRoundPro";
  src: url("/fonts/DINRoundPro-Bold.eot");
  src: local("/fonts/DINRoundPro-Bold"),
    url("/fonts/DINRoundPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DINRoundPro-Bold.woff2") format("woff2"),
    url("/fonts/DINRoundPro-Bold.woff") format("woff"),
    url("/fonts/DINRoundPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "DINRoundPro";
}

* {
  font-family: inherit;
}

.rhc-settings__content {
  height: auto !important;
}

.rhc-banner__content {
  width: auto !important;
  align-items: center;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.rhc-banner {
  padding: 15px !important;
}

.rhc-banner__content__primary,
.rhc-banner__content__secondary {
  width: auto !important;
  white-space: nowrap !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  max-width: 100% !important;
  line-height: 1.2rem !important;
}

.rhc-theme-dark {
  --background: rgb(53, 53, 53) !important;
}

.react-toggle--checked .react-toggle-track {
  background: var(--brand-primary-dark) !important;
}
