.text__red {
  padding: 1rem 0;
  color: var(--action-negative);
}

.marketing__layout {
  font-size: 16px;
  font-weight: normal;
  padding-top: calc(123px * var(--height-increment));
}

.marketing__layout h2 {
  margin: 0 0 1.25em;
  color: var(--brand-primary);
}

.h2--md {
  font-size: 2.1875rem !important;
}

.marketing__layout p {
  color: var(--brand-secondary);
}

.marketing__layout ul li,
.marketing__layout ol li {
  margin-bottom: 1rem;
}

.marketing__layout figure {
  width: 125%;
  margin: 0 0 50px;
  transform: translateX(-10%);
}

.marketing__layout figure img {
  width: 100%;
  height: auto;
}

.marketing__layout form input,
.marketing__layout form select,
.marketing__layout form textarea {
  display: block;
  width: 100%;
  margin: 0 0 2.5rem;
  padding: 2rem;
  background: #e8e8e8;
  border: 1px solid transparent;
  border-radius: 50px;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
  resize: none;
  z-index: 0;
}

.marketing__layout_input_selectContainer {
  padding: 1.25rem 2rem;
  margin: 0 0 2.5rem;
  padding: 0 !important;
  z-index: 1;
}

.marketing__layout_input_selectButton,
.marketing__layout_input_selectButtonLarge {
  display: block;
  width: 100%;
  margin: 0;
  padding: 1.25rem !important;
  background: #e8e8e8 !important;
  border: 0 !important;
  border-radius: 30px;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
  resize: none;
}

.marketing__layout_input_selectButtonLarge {
  padding: 2rem !important;
  border-radius: 50px;
}

.marketing__layout_input_selectButton svg,
.marketing__layout_input_selectButtonLarge svg {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
}

.marketing__layout_input_activeButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 0 !important;
  box-shadow: 0px 2px 10px -2px black;
}

.marketing__layout_input_selectOptions {
  position: absolute;
  box-shadow: 0px 5px 10px -5px black;
  padding: 0 0 10px !important;
  background: #e8e8e8 !important;
  border: 0;
  z-index: 9;
  border: 0 !important;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}

.marketing__layout_input_selectOption {
  padding: 1rem 1.25rem !important;
  border-radius: 0 !important;
}

.marketing__layout_input_selectOption:hover {
  color: #fff;
}

.marketing__layout button {
  display: inline-block;
  padding: 0.75rem 2.5rem;
  border: 1px solid transparent;
  border-radius: 80px;
  background: var(--brand-secondary);
  color: #fff;
  font-size: 26px;
  font-family: inherit;
  cursor: pointer;
}

.marketing__layout form input[type="checkbox"] {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #e8e8e8;
  -webkit-appearance: none;
  background: #e8e8e8;
  cursor: pointer;
  flex-shrink: 0;
}

.marketing__layout form input[type="checkbox"]:checked {
  background-color: var(--brand-primary);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg enable-background='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath fill='%23fff' d='m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z'/%3e%3c/g%3e%3c/svg%3e");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox_label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_label input[type="checkbox"] {
  margin-right: 0.5rem !important;
}

.input_error {
  border-color: var(--action-negative) !important;
}

.marketing__notice {
  padding: 1rem;
  border-radius: 80px;
  background: var(--brand-secondary);
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .marketing__layout {
    padding-top: 125px;
  }
}

@media screen and (max-width: 980px) {
  .marketing__layout {
    padding-top: 80px;
  }
}

.marketing__layout_input_selectedOptionsContainer {
  position: relative;
}
